import { combineReducers } from "redux";
import auth from './auth/reducer';
import { AUTH_LOGOUT } from './auth/action-type';

const appReducer = combineReducers({
  auth,
});

const rootReducer = (state, action) => {
  if (action.type === AUTH_LOGOUT) {
    state = undefined;
    window.location.href = "/";
  }
  return appReducer(state, action)
}

export default rootReducer;

// @flow

import React from "react";
import {
  Form,
  Grid,
  Card,
  Button,
  Icon

} from "tabler-react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import authActions from "../../redux/auth/action";
import SiteWrapperSuperAdmin from "../SiteWrapper.superadmin.react";
import { validate } from '../../validate';
import clientService from "../../services/client";
import Buttonloader from '../../common/Loader/Buttonloader'
import { changeLanguage } from '../../../src/language/index';
// import CKEditor from 'ckeditor4-react';
// import CKEditor from "react-ckeditor-component";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const validationRules = [
  {
    field: 'dValue',
    validations: ['required'],
    name: 'Dormant Notification Time'
  },
  {
    field: 'dceValue',
    validations: ['required'],
    name: 'Dormant Chat End Time'
  },
  {
    field: 'dnValue',
    validations: ['required'],
    name: 'Dormant Notification Message'
  },
  {
    field: 'ecmValue',
    validations: ['required'],
    name: 'End Chat Message'
  },
  {
    field: 'mqwValue',
    validations: ['required'],
    name: 'Maximum Queue Wait Time'
  },
  {
    field: 'qdmValue',
    validations: ['required'],
    name: 'Queue Drop Message'
  },

]

class EndSettings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fields: {
        dValue: 0,
        dceValue: 0,
        dnValue: '',
        ecmValue: '',
        mqwValue: 0,
        qdmValue: '',
        automaticEndChat: '',
      },
      message: {
        style: 'success',
        text: ''
      },
      isLoading: false,
      errors: {}
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeEditor = this.handleChangeEditor.bind(this);
    this.handleChangeEditorEndChat = this.handleChangeEditorEndChat.bind(this)
    this.handleNotification = this.handleNotification.bind(this);
    this.setLoadingToggle = this.setLoadingToggle.bind(this);
  }

  handleChange = (e, field) => {
    const { fields } = this.state, val = e.target.value;
    fields[field] = val;
    this.setState({ fields });
    //this.validate();
  }
  handleChangeEditor = (e,field) =>{
    // var newContent = e.editor.getData();
    console.log('aaaaaaaaaa',e)
    if(field === 'ecmValue'){
      var newContent = e.editor.getData();
      console.log('aaaaaaaaaa',e)
      this.setState({fields:{
       dnValue:newContent
      }})
    }else if(field === 'ecmValue'){
      var newContent = e.editor.getData();
      console.log('aaaaaaaaaa---',e)
      this.setState({fields:{
        ecmValue:newContent
       }})
    }
    
  }

  handleChangeEditorEndChat = (e,fields)=>{
    var newContent = e.editor.getData();
    console.log('aaaaaaaaaa----',e)
    this.setState({fields:{
      ecmValue:newContent
    }})
  }

  setLoadingToggle(value) {
    this.setState({ isLoading: value })
  }

  // validate
  validate() {
    const { fields } = this.state;
    const { isValid, errors } = validate(fields, validationRules);
    this.setState({ errors });
    return isValid;
  }

  //form submition
  handleSubmit = async event => {
    event.preventDefault();
    if (this.validate()) {
      console.log('working')

      this.setLoadingToggle(true);

      const data = {
        automaticEndChat: this.state.fields.automaticEndChat,
        waitingEndTime:this.state.fields.mqwValue,
          waitingEndText:this.state.fields.qdmValue,
        endChatObj:{
          dormantNotificationTime:Number(this.state.fields.dValue),
          endChatTime:Number(this.state.fields.dceValue),
          dormantNotificationText:this.state.fields.dnValue,
          endChatText:this.state.fields.ecmValue,
        }
      }
      
      clientService.settings(data)
        .then(response => {
          this.props.user.settingData.automaticEndChat =  this.state.fields.automaticEndChat
          this.props.user.settingData.waitingEndTime = this.state.fields.mqwValue
           this.props.user.settingData.waitingEndText = this.state.fields.qdmValue
          this.props.user.settingData.endChatObj = {
            dormantNotificationTime:this.state.fields.dValue,
            endChatTime:this.state.fields.dceValue,
            dormantNotificationText:this.state.fields.dnValue,
            endChatText:this.state.fields.ecmValue,
          }
          console.log(this.props.user)
          this.props.updateUserData(this.props.user);
          toast.success("Setting Changed successfully.")
          setTimeout(() => {
            this.props.history.push("/admin/dashboard");
          }, 3000)

        })
        .catch(error => {
          this.setLoadingToggle(false);
          console.log(error)
          // this.setState(() => ({ message: { text: error.response.data.message, style: 'danger' } }));
          setTimeout(() => {
            this.handleNotification();
          }, 3000)
        })
    }
    else {
      console.log('eeeeeeee');
    }
  }

  componentDidMount() {
    document.title = "Voiceoc | End Chat Settings"

    if (this.props.user != undefined) {
      const { fields } = this.state;
      fields['dValue'] = this.props.user.settingData.endChatObj.dormantNotificationTime;
      fields['dceValue'] = this.props.user.settingData.endChatObj.endChatTime
      fields['dnValue'] = this.props.user.settingData.endChatObj.dormantNotificationText
      fields['ecmValue'] = this.props.user.settingData.endChatObj.endChatText
      fields['automaticEndChat'] = this.props.user.settingData.automaticEndChat
      fields['mqwValue'] = this.props.user.settingData.waitingEndTime
      fields['qdmValue'] = this.props.user.settingData.waitingEndText
      this.setState({
        fields
      });
      console.log(this.props)
    }
  }

  handleNotification() {
    this.setState(() => ({ message: { text: '', style: '' } }));
  }

  handleChangecheckbox(e){
    let isChecked = e.target.checked ? true : false;
    const { fields } = this.state;
    fields['automaticEndChat'] = isChecked
    this.setState({
      fields
    });
    console.log(this.state.fields)
  }

  render() {
    const { message } = this.state;

    return (
      <SiteWrapperSuperAdmin>
        <Grid.Col lg={5} className="mx-auto ">
          <Card>
            <Card.Header>
              <Card.Title>{changeLanguage(this.props.siteLanguage,'endchat_setting')}</Card.Title>
            </Card.Header>
            <Card.Body>
              {!!message.text ?
                <div className={'alert alert-' + message.style} >
                  <p>{message.text}</p>
                  <Icon prefix="fa" name="times" onClick={this.handleNotification}></Icon>
                </div>
                :
                null
              }
              <form onSubmit={this.handleSubmit}>
                <Form.FieldSet>

                <Form.Group label="">
                  <Form.Checkbox
                      isInline
                      label={changeLanguage(this.props.siteLanguage,'auto_chat_end')}
                      name="example-inline-checkboxes"
                      value="option1" 
                      checked={this.state.fields.automaticEndChat}
                      onChange={(e) => this.handleChangecheckbox(e)}
                    />
                  

                  </Form.Group>


                  <Form.Group
                    isRequired
                    label={changeLanguage(this.props.siteLanguage,'dormant_noti_time')}
                  >
                    <Form.Input name="dValue" 
                    disabled={!this.state.fields.automaticEndChat}
                      type="text"
                      placeholder={changeLanguage(this.props.siteLanguage,'dormant_noti_time')}
                      onChange={(e) => this.handleChange(e, 'dValue')}
                      value={this.state.fields.dValue}
                    />
                    <label style={{ display: this.state.errors.dValue ? 'block' : 'none' }} className="error">{this.state.errors.dValue}</label>
                  </Form.Group>

                  <Form.Group
                    isRequired
                    label={changeLanguage(this.props.siteLanguage,'dormant_end')}
                  >
                    <Form.Input name="dceValue" 
                     disabled={!this.state.fields.automaticEndChat}
                      type="text"
                      placeholder={changeLanguage(this.props.siteLanguage,'dormant_end')}
                      onChange={(e) => this.handleChange(e, 'dceValue')}
                      value={this.state.fields.dceValue}
                    />
                    <label style={{ display: this.state.errors.dceValue ? 'block' : 'none' }} className="error">{this.state.errors.dceValue}</label>
                  </Form.Group>

                  <Form.Group
                    isRequired
                    label={changeLanguage(this.props.siteLanguage,'dormant_noti_msg')}
                  >
                     <Form.Textarea 
                     name="dceValue" 
                     disabled={!this.state.fields.automaticEndChat}
                      type="text"
                      placeholder={changeLanguage(this.props.siteLanguage,'dormant_end')}
                      onChange={(e) => this.handleChange(e, 'dnValue')}
                      value={this.state.fields.dnValue}
                    />
                 
                  
                  
                    <label style={{ display: this.state.errors.dnValue ? 'block' : 'none' }} className="error">{this.state.errors.dnValue}</label>
                  </Form.Group>

                   <Form.Group
                    isRequired
                    label={changeLanguage(this.props.siteLanguage,'end_chat')}
                  >
                   
                  
                    <Form.Textarea name="ecmValue"
                      type="text"
                      placeholder={changeLanguage(this.props.siteLanguage,'end_chat')}
                      onChange={(e) => this.handleChange(e, 'ecmValue')}
                      value={this.state.fields.ecmValue} />
                    <label style={{ display: this.state.errors.ecmValue ? 'block' : 'none' }} className="error">{this.state.errors.ecmValue}</label>
                  </Form.Group>

                  <Form.Group
                    isRequired
                    label={changeLanguage(this.props.siteLanguage,'maximum_queue_wait')}
                  >
                  <Form.Input name="mqwValue"
                      type="text"
                      placeholder={changeLanguage(this.props.siteLanguage,'maximum_queue_wait')}
                      onChange={(e) => this.handleChange(e, 'mqwValue')}
                      value={this.state.fields.mqwValue} />
                    <label style={{ display: this.state.errors.mqwValue ? 'block' : 'none' }} className="error">{this.state.errors.mqwValue}</label>
                  </Form.Group>

                  <Form.Group
                    isRequired
                    label={changeLanguage(this.props.siteLanguage,'drop_msg')}
                  >
                  <Form.Textarea name="qdmValue"
                      type="text"
                      placeholder={changeLanguage(this.props.siteLanguage,'drop_msg')}
                      onChange={(e) => this.handleChange(e, 'qdmValue')}
                      value={this.state.fields.qdmValue} />
                    <label style={{ display: this.state.errors.qdmValue ? 'block' : 'none' }} className="error">{this.state.errors.qdmValue}</label>
                  </Form.Group>



                </Form.FieldSet>

                {!this.state.isLoading ?
                  <Button color="primary btn-block" type="submit">{changeLanguage(this.props.siteLanguage,'change')}</Button>
                  :
                  <Buttonloader loading={this.state.isLoading}></Buttonloader>
                }
              </form>
            </Card.Body>
          </Card>
        </Grid.Col>
        <ToastContainer autoClose={5000} position={'bottom-right'} />
      </SiteWrapperSuperAdmin >
    )
  }

}

function mapStateToProps(state) {
  console.log('statestate',state)
  return {
    user: state.auth.user,
    siteLanguage:state.auth.siteLanguage
  }
}
function mapDispatchToProps(dispatch) {
  return {
    updateUserData: (payload) => {
      dispatch(authActions.updateUserData(payload))
    }
  }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EndSettings));

import * as React from "react";
import { Link, withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import {
  Card,
  Button,
  Form
} from "tabler-react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validate } from '../validate';
import authActions from "../redux/auth/action";
import loginService from "../services/user";
import Buttonloader from '../common/Loader/Buttonloader'
import { changeLanguage } from '../../src/language/index'
const validationRules = [
  {
    field: 'email',
    validations: ['required', 'email'],
    name: 'Email'
  },
  {
    field: 'password',
    validations: ['required'],
    name: 'Password'
  }
];

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        email: '',
        password: '',
        prefix: ''
      },
      message: {
        style: 'success',
        text: ''
      },
      errors: {},
      isLoading: false,
      pageLoading: true
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleNotification = this.handleNotification.bind(this);
    this.setLoadingToggle = this.setLoadingToggle.bind(this);
  }

  componentDidMount() {
    document.title = "Voiceoc | Login"
    this.setState({ pageLoading: false })
  }

  componentWillReceiveProps(nextProps) {
    
    if (nextProps && nextProps.authToken !== undefined) {
      localStorage.setItem("jwt_token", nextProps.authToken);
      if (nextProps.user && nextProps.authToken) {
        //this.props.history.push("/admin/dashboard");
      }
    }
  }

  handleChange = (e, field) => {
    const { fields } = this.state, val = e.target.value;
    fields[field] = val;
    this.setState({ fields });
  }

  validate() {
    const { fields } = this.state;
    const { isValid, errors } = validate(fields, validationRules);
    this.setState({ errors });
    return isValid;
  }


  handleSubmit = async event => {
    event.preventDefault();
    if (this.validate()) {
      let data = {
        email: this.state.fields.email,
        password: this.state.fields.password
      }
      this.setLoadingToggle(true);

      loginService.login(data)
        .then(response => {
          console.log('response',response.data.authToken)
          localStorage.setItem('jwtToken', response.data.authToken)

          if (response.statusCode === 200) {
            this.props.setData(response);
            if (response.data.role === 'admin') {
              this.props.setReedirectUrl({ path: '/agent/dashboard/1?reload=true' });
              this.props.history.push('/agent/dashboard/1?reload=true');
            } else {
              this.props.setReedirectUrl({ path: '/admin/dashboard' });
              this.props.history.push('/admin/all-chat/1');
            }
          } else {
            toast.error(response.data.message)
          }
          this.setLoadingToggle(false);
        })
        .catch((err) => {
          if (err && err.data && err.data.message) {
            toast.error(err.data.message);
          } else {
            toast.error('Server error');
          }
          this.setLoadingToggle(false);
        });

    }
  }


  setLoadingToggle(value) {
    this.setState({ isLoading: value })
  }

  handleNotification() {
    this.setState(() => ({ message: { text: '', style: '' } }));
  }

  render() {

    const { isLoggedIn } = this.props;
    const { from } = this.props.location.state || { from: { pathname: '/?reload=true' } };
    const { message } = this.state;
    const { pageLoading } = this.state;
    return (
      isLoggedIn ? <Redirect to={from} /> :
        <div className="page">
          {/* {pageLoading ? 'loading....' : */}

          <div className="page-single">
            <div className="container">
              <div className="row">
                <div className="col col-login mx-auto">
                  <div className="text-center mb-6">
                    <img
                      src={process.env.REACT_APP_publicUrl + '/images/logo-voiceoc-new.svg'}
                      className="logoheight" alt="logo"></img>
                  </div>

                  <form onSubmit={this.handleSubmit}>
                    <Card>
                      <Card.Header>
                        <Card.Title>Login to your account</Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <div>
                          <Form.FieldSet>
                            <Form.Group
                              isRequired
                              label="Email Address"
                            >
                              <Form.Input name="email"
                                onChange={(e) => this.handleChange(e, 'email')}
                                placeholder="Email"
                                value={this.state.fields.email}
                              />
                              <label style={{ display: this.state.errors.email ? 'block' : 'none' }} className="error">{this.state.errors.email}</label>
                            </Form.Group>
                            <Form.Group isRequired label="Password">
                              <Form.Input
                                name="password"
                                placeholder="Password..."
                                type="password"
                                onChange={(e) => this.handleChange(e, 'password')}
                                value={this.state.fields.password}
                              />
                              <label style={{ display: this.state.errors.password ? 'block' : 'none' }} className="error">{this.state.errors.password}</label>
                            </Form.Group>
                          </Form.FieldSet>
                          {!this.state.isLoading ?
                            <Button color="primary btn-block" type="submit">Login</Button>
                            :
                            <Buttonloader loading={this.state.isLoading}></Buttonloader>
                          }
                          <Link className="backcolor" to="/forgot-password">Forgot Password</Link>
                        </div>
                      </Card.Body>
                    </Card>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* } */}

          <ToastContainer autoClose={5000} position={'bottom-right'} />
        </div>

    );
  }
}
function mapStateToProps(state) {
  return {
    isLoggedIn: state.auth.isAuthenticated,
    redirectDestination: state.auth.redirectDestination
  }
}
function mapDispatchToProps(dispatch) {
  return {
    setData: (payload) => {
      dispatch(authActions.authLogin(payload))
    },
    setReedirectUrl: (payload) => {
      dispatch(authActions.setReedirectUrl(payload))
    }
  }

}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
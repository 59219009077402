import * as React from "react";
import { Link } from 'react-router-dom'
import {
  Card,
  Button,
  Form
} from "tabler-react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validate } from '../validate';
import loginService from "../services/user";
import Buttonloader from '../common/Loader/Buttonloader'


const validationRules = [
  {
    field: 'email',
    validations: ['required', 'email'],
    name: 'Email'
  }
];

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        email: '',
        prefix: ''
      },
      alert: {
        show: false,
        style: 'success',
      },
      errors: {},
      isLoading: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.setLoadingToggle = this.setLoadingToggle.bind(this);
  }

  componentDidMount() {
    document.title = "Voiceoc | Forgot password"
  }

  handleSubmit = async event => {

    event.preventDefault();
    if (this.validate()) {
      const data = {
        email: this.state.fields.email
      }
      this.setLoadingToggle(true);
      try {
        await loginService.forgotPassword(data)
          .then(response => {
            if (response.statusCode === 200) {
              toast.success('Please check your mail')
              setTimeout(() => {
                this.props.history.push("/login");
              }, 3000)
              this.setState({ alert });
            } else {
              this.setState(() => ({ message: { text: 'Invalid Email', style: 'danger' } }));
            }
            this.setLoadingToggle(false);
          })
          .catch((err) => {
            if (!!err.response.data.message) {
              toast.error(err.response.data.message)
            } else {
              toast.error('Server error,Please try after some time')
            }
            this.setLoadingToggle(false);
          });
      } catch (e) {
        console.error(e.message);
      }
    }
  }

  setLoadingToggle(value) {
    this.setState({ isLoading: value })
  }

  handleChange = (e, field) => {
    const { fields } = this.state, val = e.target.value;
    fields[field] = val;
    this.setState({ fields });
  }

  validate() {
    const { fields } = this.state;
    const { isValid, errors } = validate(fields, validationRules);
    this.setState({ errors });
    return isValid;
  }

  render() {


    return (

      <div className="page">
        <div className="page-single">
          <div className="container">
            <div className="row">
              <div className="col col-login mx-auto">
                <div className="text-center mb-6">
                  <img
                    src={process.env.REACT_APP_publicUrl + '/images/logo-voiceoc-new.svg'} className="logoheight" alt="logo"></img>
                </div>
                <form onSubmit={this.handleSubmit}>
                  {
                    this.state.alert.show &&
                    <div className={`alert alert-${this.state.alert.style}`}>
                      <p>{this.state.alert.text}</p>
                    </div>
                  }
                  <Card>
                    <Card.Header>
                      <Card.Title>Forgot Password</Card.Title>
                    </Card.Header>

                    <Card.Body>
                      <p className="text-muted">Enter your email address and your password will be reset and emailed to you.</p>
                      <div>
                        <Form.FieldSet>
                          <Form.Group
                            isRequired
                            label="Email Address"
                          >
                            <Form.Input name="email"
                              onChange={(e) => this.handleChange(e, 'email')}
                              value={this.state.fields.email}
                            />
                            <label style={{ display: this.state.errors.email ? 'block' : 'none' }} className="error">{this.state.errors.email}</label>
                          </Form.Group>
                        </Form.FieldSet>
                        {!this.state.isLoading ?
                          <Button color="primary btn-block" type="submit">Request Password Change</Button>
                          :
                          <Buttonloader loading={this.state.isLoading}></Buttonloader>
                        }

                        <Link className="backcolor" to="/login">Login</Link>
                      </div>
                    </Card.Body>
                  </Card>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer autoClose={5000} position={'bottom-right'} />
      </div>

    );
  }
}

export default ForgotPassword;
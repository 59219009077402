// @flow

import React from "react";
import {
  Form,
  Grid,
  Card,
  Button,
  Icon,
  Page,
} from "tabler-react";
import Select from 'react-select';
import $ from 'jquery';


import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import SiteWrapper from "../SiteWrapper.admin.react";
import { validate } from '../../validate';
import clientService from "../../services/client";
import botService from "../../services/bot";
import userService from "../../services/user";
import authActions from "../../redux/auth/action";
import Buttonloader from '../../common/Loader/Buttonloader';
import Loader from '../../common/Loader/loader'

import { changeLanguage } from '../../../src/language/index'

const validationRules = [
  {
    field: 'name',
    validations: ['required','min:1', 'max:50'],
    name: 'Category'
  },
  {
    field: 'text',
    validations: ['required', 'min:3', 'max:50'],
    name: 'Template Id'
  },
  {
    field: 'hsmMessage',
    validations: ['required','min:1'],
    name: 'Hsm Message'
  },
];
class ButtonEdit extends React.Component {

  constructor(props) {
    super(props);
    console.log('najmu',this.props.templateEditedData)
    this.state = {
      fields: {
        name: this.props.templateEditedData.name,
        text: this.props.templateEditedData.text,
      },
     
      templateEditedData: this.props.templateEditedData,
      isLoading: false,
      message: {
        style: 'success',
        text: ''
      },
      errors: {},
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleNotification = this.handleNotification.bind(this);
    this.setLoadingToggle = this.setLoadingToggle.bind(this);
    //console.log(this.props.templateEditedData);
    //this.handleChangeQuestionValue = this.handleChangeQuestionValue(bind);
  }

  //handle change
  handleChange = (e, field) => {
    const { fields } = this.state, val = e.target.value;
    fields[field] = val;
    this.setState({ fields });
    //this.validate();
  }


   handleChangeEditbot(){
  }


  setLoadingToggle(value) {
    this.setState({ isLoading: value });
  }

  deleteOption(event,$this){
    var ele = $('.all_options');
    let _self = this;
    $this.parent().parent().parent().remove();
    this.manageOptionSequence(ele);
    const optionLength = $('.template-option').length;
    if(optionLength <2){
      $('.delete_option').hide();
    }
     
  }

  manageOptionSequence(ele){
    $($(ele)).children().each(function( index,element ) {

      var text = $(element).find('.form-label').text();
      var count = index+1;
      $(element).find('.form-label').html('Option '+count+'<span class="form-required">*</span>');
    });
  }

  deleteQuestion(event){
    $('#'+event.target.id).parent('.questions').remove();
    $('#questions_'+event.target.dataset.quesid).remove();
    $('#delete_option_'+event.target.dataset.quesid).remove();
    this.manageSequence();
  }

  //manage question sequesnce
  manageSequence(){
    var quesLength =  $('.questions').length;
    $($('.questions')).each(function( index ) {
      var ele = $('.questions')[index];
      var text = $(ele).find('.form-label').text();
      var count = index+1;
      $(ele).find('.form-label').html('Question '+count+'<span class="form-required">*</span>');
      if(quesLength === 1){
        $(ele).find('.delete_question').hide();
      }
      else{
        $(ele).find('.delete_question').show();
      }
    });

  }


   addNewOption(event,id){

    this.setState({
      deleteFirstO: true
    });
    //<i class="fa fa-plus addoptionsdynamic" id="question_'+id+'"></i>

    event.preventDefault();
    const optionLength = $('.template-option').length+1;
    const optionHtml = '<div class="template-option_'+optionLength+' template-option"><fieldset class="form-fieldset"><div id="all_options"><div class="form-group options"><label class="form-label">Option '+optionLength+'<span class="form-required">*</span></label><input name="text[]" class="form-control textclass" type="text" placeholder="Text" value=""></div><div class="form-group options"><input name="optionvalue[]" class="form-control optionvalue" type="text" placeholder="Value" value=""></div><i class="fa fa-trash delete_option" data-optionid="'+optionLength+'" id="delete_option_'+optionLength+'" style="display: block;"></i></div></fieldset></div>';
    $('.all_options').append(optionHtml);
    if(optionLength >1){
      $('.delete_option').show();
    }
  }


  //submit form
    //submit form
  handleSubmit = async event => {
    event.preventDefault();
    if (this.validate()) {
      const queationValues =  this.validateQuestions();
        
          let data = {
          name: this.state.fields.name,
          text: this.state.fields.text,
        }
      
        this.setLoadingToggle(true);
        botService.updateButton(data,this.state.templateEditedData._id)
        .then(response => {
          toast.success("Button updated successfully.")
          this.props.onCancelEditForm(true);
          this.setLoadingToggle(true);
          
         
        })
        .catch(error => {
          toast.error(error.response.data.message)
          this.setLoadingToggle(false);
        })
      
     
    }
  }

  //validateQuestions
  validateQuestions(){
    var options = [];
    $('.template-option').each(function( index,ele ) {
      var $optionele = $(ele).next();
      let textclass = $(ele).find('.textclass').val();
      let optionvalue = $(ele).find('.optionvalue').val();
     
       let data = {
          "text": textclass.toString(),
          "value": optionvalue.toString(),
        }
        if(textclass == '' || optionvalue == ""){
          options = [];
          return false;
        }
        options.push(data);
    });
    return options;
  }

  handleNotification() {
    this.setState(() => ({ message: { text: '', style: '' } }));
  }

  validate() {
    const { fields } = this.state;
    const { isValid, errors } = validate(fields, validationRules);
    this.setState({ errors });
    return isValid;
  }

  componentDidMount() {
    document.title = "Voiceoc | Bot Edit";
    let _self = this;
    
    //delete question
    $(document).on('click', '.delete_question', function(event){
      _self.deleteQuestion(event);
    });

    //delete option
    $(document).on('click','.deleteOption', function(event){
      _self.deleteOption(event,$(this));
    });
  }

  //close edit form
  closeEditForm(e){
    this.props.onCancelEditForm();
  }

  render() {

    const { message } = this.state;
    const { templateEditedData } = this.state;
   
    return (
       <Grid.Row >
          <Card.Body >
        
                <form onSubmit={this.handleSubmit} className="row"> 
                <div className="bot-details col col-lg-10 mx-auto">
                      <Form.FieldSet>
                      <Form.Group label=" " className="form3 form0"
                            isRequired
                            label={changeLanguage(this.props.siteLanguage,'button_name')}
                          >
                            <Form.Input name="name"
                              onChange={(e) => this.handleChange(e, 'name')}
                              placeholder={changeLanguage(this.props.siteLanguage,'button_name')}
                              value={this.state.fields.name} />

                            <label style={{ display: this.state.errors.name ? 'block' : 'none' }} className="error">{this.state.errors.name}</label>
                          </Form.Group>
                         
                          <Form.Group label=" "  className="form0" 
                            isRequired
                            label={changeLanguage(this.props.siteLanguage,'button_text')}
                          >
                            <Form.Input name="text"
                              onChange={(e) => this.handleChange(e, 'text')}
                              placeholder={changeLanguage(this.props.siteLanguage,'button_text')}
                              value={this.state.fields.text} />

                            <label style={{ display: this.state.errors.text ? 'block' : 'none' }} className="error">{this.state.errors.text}</label>
                          </Form.Group>

                           {/* <Form.Group
                            isRequired
                            label="Hsm Message"
                          >
                            <Form.Textarea name="hsmMessage" rows="10"
                              onChange={(e) => this.handleChange(e, 'hsmMessage')}
                              placeholder='Hsm Message'
                              value={this.state.fields.hsmMessage} />

                            <label style={{ display: this.state.errors.hsmMessage ? 'block' : 'none' }} className="error">{this.state.errors.hsmMessage}</label>
                          </Form.Group> */}
                          
                      </Form.FieldSet>
                    </div>
  
                  

                     {!this.state.isLoading ?
                     <div className="btn_set col-lg-2 btn-from">
                        <Button className=" aligncentre btn btn-primary " type="submit" style={{ clear:'both' }}>
                        {changeLanguage(this.props.siteLanguage,'update')}
                        </Button>
                        <Button className=" aligncentre btn btn-secondary" type="button" onClick={(e) => this.closeEditForm(e)}>
                        {changeLanguage(this.props.siteLanguage,'cancel')}
                        </Button>
                      </div>
                      :
                      <Buttonloader loading={this.state.isLoading} 
                      addNewClass="col-lg-4 aligncentre"></Buttonloader>
                    }
                 
                  </form>
                </Card.Body> 
     </Grid.Row>
    )
  }

}


const mapStateToProps = (state) => {
  return {
    siteLanguage:state.auth.siteLanguage
  }
}
const bindActions = (dispatch) => {
  return {
    updateToastState: (payload) => dispatch(authActions.updateToastState(payload))
  }
}

//export default BotEdit;
export default withRouter(connect(mapStateToProps, bindActions)(ButtonEdit));


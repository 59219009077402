import * as React from "react";
import { Link } from 'react-router-dom'
import {
  Card,
  Button,
  Form
} from "tabler-react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validate } from '../validate';
import userService from "../services/user";

const validationRules = [
  {
    field: 'password',
    validations: ['required', 'min:6', 'max:15'],
    name: 'Password'
  },
  {
    field: 'confirm_password',
    validations: ['required', 'min:6', 'max:15'],
    name: 'Confirm Password'
  }
];

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        password: '',
        confirm_password: '',
        prefix: ''
      },
      alert: {
        show: false,
        style: 'success',
      },
      errors: {},
      loading: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    document.title = "Voiceoc | Reset password"
  }

  handleChange = (e, field) => {
    const { fields } = this.state, val = e.target.value;
    fields[field] = val;
    this.setState({ fields });
  }

  validate() {
    const { fields } = this.state;
    const { isValid, errors } = validate(fields, validationRules);
    this.setState({ errors });
    return isValid;
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.validate()) {
      userService.forgotPassword(this.state.fields)
        .then(response => {
          console.log(response);
          //toast.success("Client created successfully.")
          // setTimeout(() => {
          //   this.props.history.push("/admin/clients/1");
          // }, 3000)

        })
        .catch(error => {
          this.setState(() => ({ message: { text: error.response.data.message, style: 'danger' } }));
          setTimeout(() => {
            this.handleNotification();
          }, 3000)
        })
    }
  }

  render() {
    return (

      <div className="page">
        <div className="page-single">
          <div className="container">
            <div className="row">
              <div className="col col-login mx-auto">
                <div className="text-center mb-6">
                  <img 
                    src={process.env.REACT_APP_publicUrl + '/images/logo.png'} className="logoheight" alt="logo"></img>
                </div>
                <form onSubmit={this.handleSubmit}>
                  <Card>
                    <Card.Header>
                      <Card.Title>Reset Password</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <div>
                        <Form.FieldSet>
                          <Form.Group
                            isRequired
                            label="Password"
                          >
                            <Form.Input name="password"
                              type="password"
                              placeholder="Password"
                              onChange={(e) => this.handleChange(e, 'password')}
                              value={this.state.fields.password}
                            />
                            <label style={{ display: this.state.errors.password ? 'block' : 'none' }} className="error">{this.state.errors.password}</label>
                          </Form.Group>
                          <Form.Group isRequired label="Confirm Password">
                            <Form.Input
                              name="confirm_password"
                              placeholder="Confirm Password"
                              type="password"
                              onChange={(e) => this.handleChange(e, 'confirm_password')}
                              value={this.state.fields.confirm_password}
                            />
                            <label style={{ display: this.state.errors.confirm_password ? 'block' : 'none' }} className="error">{this.state.errors.confirm_password}</label>
                          </Form.Group>
                        </Form.FieldSet>
                        <Button color="primary btn-block" type="submit">Reset Password</Button>
                        <Link to="/login">Login</Link>
                      </div>
                    </Card.Body>
                  </Card>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default ResetPassword;
import LoginPage from "../auth/Login.react";
import ForgotPassword from "../auth/forgot-password.react";
import ResetPassword from "../auth/reset-password.react";
import SuperAdminDashboard from "../admin/SuperAdminDashboard-react";
import AdminDashboard from "../admin/AdminDashboard-react";
import Chat from '../../src/admin/chat/Chat'
import AllChat from '../../src/admin/chat/AllChat'
// client
import ClientList from "../admin/clients/Clients-list-react";
import ClientCreate from "../admin/clients/Clients-create-react";
import ClientEdit from "../admin/clients/Clients-edit-react";
import ChangePassword from "../admin/settings/change-password.react";
import Settings from "../admin/settings/settings.react";
import EndSettings from "../admin/settings/end-settings.react";
import GoogleSettings from '../admin/settings/google-settings.react';

import ClientProfile from "../clients/settings/profile.react";
import DashboardClient from "../clients/Dashboard-client-react";
import ClientChangePassword from "../clients/settings/change-password.react";
import TemplateCreate from "../admin/templates/template-create-react";
import ButtonCreate from "../admin/buttons/button-create-react";
import ShareData from "../admin/Dashboard-client-react";
import Analytic from "../admin/analytics/Analytic";
import LeadDashboard from "../admin/leadDashboard";

import {
  Error404,
} from "../pages";


const routes = [
  {
    path: '/',
    exact: true,
    auth: true,
    component: SuperAdminDashboard
  },
  {
    path: '/forgot-password',
    exact: true,
    auth: false,
    component: ForgotPassword
  },
  {
    path: '/reset-password',
    exact: true,
    auth: false,
    component: ResetPassword
  },
  {
    path: '/login',
    exact: true,
    auth: false,
    component: LoginPage
  },
  {
    path: '/admin/dashboard',
    exact: true,
    auth: true,
    component: SuperAdminDashboard
  },
  {
    path: '/admin/clients/:page',
    exact: true,
    auth: true,
    component: ClientList
  },
  {
    path: '/admin/dashboard/:id',
    exact: true,
    auth: true,
    component: SuperAdminDashboard
  },
  {
    path: '/admin/:clientId/dashboard/:page',
    exact: true,
    auth: true,
    component: ShareData
  },
  {
    path: '/admin/chat/:page',
    exact: true,
    auth: true,
    component: Chat
  },
  {
    path: '/admin/all-chat/:page',
    exact: true,
    auth: true,
    component: AllChat
  },
  {
    path: '/admin/all-chat/:page/:userId',
    exact: true,
    auth: true,
    component: AllChat
  },
  {
    path: '/admin/chat/:page/:userId',
    exact: true,
    auth: true,
    component: Chat
  },
  {
    path: '/admin/dashboard/:id/:page',
    exact: true,
    auth: true,
    component: SuperAdminDashboard
  },
  {
    path: '/admin/dashboard/:id/:page/:userId',
    exact: true,
    auth: true,
    component: SuperAdminDashboard
  },
  {
    path: '/admin/client/add',
    exact: true,
    auth: true,
    component: ClientCreate
  },
  {
    path: '/admin/client/edit/:id',
    exact: true,
    auth: true,
    component: ClientEdit
  },
  {
    path: '/admin/change-password',
    exact: true,
    auth: true,
    component: ChangePassword
  },
  {
    path: '/admin/settings',
    exact: true,
    auth: true,
    component: Settings
  },
  {
    path :'/admin/google-settings',
    exact: true,
    auth : true,
    component: GoogleSettings
  },
  {
    path: '/admin/end-settings',
    exact: true,
    auth: true,
    component: EndSettings
  },
  {
    path: '/agent/dashboard/:page',
    exact: true,
    auth: true,
    component: AdminDashboard
  },
  {
    path: '/agent/dashboard/:page/:userId',
    exact: true,
    auth: true,
    component: AdminDashboard
  },
  {
    path: '/agent/change-password',
    exact: true,
    auth: true,
    component: ClientChangePassword
  },
  {
    path : '/agent/button',
    exact: true,
    auth :true,
    component:ButtonCreate
  },
  {
    path: '/client/:clientId/change-password',
    exact: true,
    auth: true,
    component: ClientChangePassword
  },
  {
    path: '/client/:clientId/profile',
    exact: true,
    auth: true,
    component: ClientProfile
  },
  {
    path: '/admin/templates',
    exact: true,
    auth: true,
    component: TemplateCreate
  },
  {
    path: '/admin/analytics',
    exact: true,
    auth: true,
    component: Analytic
  },
  {
    path: '/admin/lead-dashboard/:page',
    exact: true,
    auth: true,
    component: LeadDashboard
  },
  {
    path: '*',
    auth: false,
    component: LoginPage
  },
 

];

export default routes;

import React from "react";
import Popup from "reactjs-popup";
import {
  Grid,
  Card,
  Table,
  Page,
  colors,
  Form
} from "tabler-react";

class ViewButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      open: this.props.openPopup ,
    };
    this.closeModal = this.closeModal.bind(this);
  }
 
  closeModal() {
    this.props.onChange(false);
  }

  render() {
    const openPopup = this.props.openPopup.open;
    const templateData = this.props.openPopup.data;
    return (
      <div>
       
        <Popup
          open={openPopup}
          onClose={this.closeModal}
          closeOnDocumentClick
        >
         { templateData ?
           <div className="card">
            <div className="card-header">
              <h3 className="card-title">
                <span>Button Name:  {templateData.category} </span>
                <br />
                <span>Button Text:  {templateData.templateId} </span>
              </h3>

             
              <div className="card-options">
                <a className="card-options-remove cursorPointer" 
                  data-toggle="card-remove" 
                  onClick={() => {
                  this.closeModal();
                }}>
                  <i className="fe fe-x"></i>
                </a>
              </div>
            </div>
          
         
          </div>

         :
         null
       }

          
        </Popup>
      </div>
    );
  }
}

export default ViewButton
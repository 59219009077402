import React from "react";
import Popup from "reactjs-popup";
import {
  Grid,
  Card,
  Table,
  Page,
  colors,
  Form
} from "tabler-react";

class CsvErrorMesage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      open: this.props.openPopup ,
    };
    //this.openModal = this.props.openPopup;
    this.closeModal = this.closeModal.bind(this);
  }
 
  closeModal() {
    this.props.onChange(false);
  }

  render() {
    const openPopup = this.props.openPopup.open;
    console.log(this.props)
    return (
      <div>
       
        <Popup
          open={openPopup}
          onClose={this.closeModal}
          closeOnDocumentClick
        >
       
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Error List in Csv</h3>
              <div className="card-options">
                <a className="card-options-remove cursorPointer" 
                  data-toggle="card-remove" 
                  onClick={() => {
                  this.closeModal();
                }}>
                  <i className="fe fe-x"></i>
                </a>
              </div>
            </div>
            <div className="card-body">
             { this.props.openPopup.data }
            </div>
            
          </div>
          
        </Popup>
      </div>
    );
  }
}

export default CsvErrorMesage
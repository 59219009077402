import axios from 'axios';
import { store } from './redux/store/store';
import actions from './redux/auth/action';

let axiosApiInstance = axios.create();

export const axiosBasicAuth = axios.create();

axiosApiInstance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

axiosApiInstance.interceptors.request.use(function (config) {
  const { jwtToken } = localStorage;
  if (typeof jwtToken != 'undefined' && jwtToken !== null) {
    config.headers.common['Authorization'] = `Bearer ${jwtToken}`;
  }else{
    // var username = "voiceoc_@pp_livpure";
    // var password = "voiceoc_@pp_livpure@2020";

    var username = 'voiceoc_aster_@pp_voiceoc';
    var password = 'voiceoc_aster_@pp_voiceoc@2021';
    var authorizationBasic = username + ':' + password;
    var basicAuth = 'Basic ' + btoa(username + ':' + password);
        config.headers.common['Authorization'] = ` ${basicAuth}`;
  }
  return config;
}, function (error) {
  console.log('najjjjjjjjjjjjjjj')
  if (error.response.status === 401) {
    store.dispatch(actions.authLogout())
  }
  if (error.response.status === 500) {
    if (error.response.data.message === "Token expired please login again.") {
      store.dispatch(actions.authLogout());
    }
  }
  return Promise.reject(error);
});


axiosApiInstance.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response  &&  error.response.status === 401 ) {
    store.dispatch(actions.authLogout())
  }
  if ( error.response && error.response.status === 500) {
    if ( error.response.data && error.response.data.message === "Token expired please login again.") {
      store.dispatch(actions.authLogout());
    }
  }
 return Promise.reject(error);
});


axiosBasicAuth.interceptors.request.use(function (config) {
    // var username = "voiceoc_@pp_livpure";
    // var password = "voiceoc_@pp_livpure@2020";

    var username = 'voiceoc_aster_@pp_voiceoc';
    var password = 'voiceoc_aster_@pp_voiceoc@2021';
    var authorizationBasic = username + ':' + password;
    var basicAuth = 'Basic ' + btoa(username + ':' + password);
        config.headers.common['Authorization'] = ` ${basicAuth}`;
  return config;
}, function (error) {
  return Promise.reject(error);
});

axiosBasicAuth.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response  &&  error.response.status === 401 ) {
    store.dispatch(actions.authLogout())
  }
  if ( error.response && error.response.status === 500) {
    if ( error.response.data && error.response.data.message === "Token expired please login again.") {
      store.dispatch(actions.authLogout());
    }
  }
 return Promise.reject(error);
});

export default axiosApiInstance;
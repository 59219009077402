// @flow

import * as React from "react";
import { NavLink, withRouter, Link, Redirect } from "react-router-dom";
import { connect } from 'react-redux';

import {
  Site,
  RouterContextProvider,
} from "tabler-react";
import authActions from "../redux/auth/action";
import $ from 'jquery';
// import type { NotificationProps } from "tabler-react";
import ReactHtmlParser from 'react-html-parser';

import { changeLanguage } from '../../src/language/index'

class SiteWrapperSuperAdmin extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      siteLanguage:'',
      showLang:'ENG',
      user: this.props.user,
    }
    this.routeChange = this.routeChange.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
    // this.props.setLang({"siteLanguage": 'en'})
  }

  routeChange(path) {
    if (path === '/login') {
      this.props.logOut();
    }
    this.props.history.push(path);
  }
  changeLanguage (language) {
    if(this.props.siteLanguage === 'ar'){
      this.props.setLang({"siteLanguage": 'en'})
      this.setState({siteLanguage:'en'})
      this.setState({showLang:'ENG'})
    }else{
      this.props.setLang({"siteLanguage": 'ar'})
      this.setState({siteLanguage:'ar'})
      this.setState({showLang:'ARABIC'})

    } 
    // window.location.reload(1);
}

getMenu(){
  let navBarItems
   navBarItems = [
    {
      value: "All Chat",
      to: "/admin/all-chat/1",
      icon: "message-square",
      LinkComponent: withRouter(NavLink),
      useExact: true,
    },
      
    // {
    //   value: changeLanguage(this.props.siteLanguage,'share'),
    //   to: '/admin/' + this.state.user._id + '/dashboard/1',
    //   icon: "share",
    //   LinkComponent: withRouter(NavLink),
    //   useExact: true,
    // },
    // {
    //   value:changeLanguage(this.props.siteLanguage,'templates'),
    //   to: "/admin/templates",
    //   icon: "map",
    //   LinkComponent: withRouter(NavLink),
    //   useExact: true,
    // },
   
   
    {
      value: changeLanguage(this.props.siteLanguage,'agents'),
      icon: "user",
      subItems: [
        {
          value: changeLanguage(this.props.siteLanguage,'view_agents'),
          to: "/admin/clients/1",
          LinkComponent: withRouter(NavLink),
        },
        {
          value: changeLanguage(this.props.siteLanguage,'add_new_agents'),
          to: "/admin/client/add",
          LinkComponent: withRouter(NavLink),
        },
      ],
    },
    {
      value: changeLanguage(this.props.siteLanguage,'dashboard') ,
      to: "/admin/dashboard",
      icon: "home",
      LinkComponent: withRouter(NavLink),
      useExact: true,
    },
    
   

    {
      value:'Lead Dashboard',
      to: "/admin/lead-dashboard/1",
      icon: "bar-chart",
      LinkComponent: withRouter(NavLink),
      useExact: true,
    },
    
    {
      value:changeLanguage(this.props.siteLanguage,'analytics'),
      to: "/admin/analytics",
      icon: "bar-chart",
      LinkComponent: withRouter(NavLink),
      useExact: true,
    },
   
  ];
  return navBarItems;
  
}
  componentDidMount() {
    this.menuSelection();
    //const { user } = this.props;
    // if (user.role === "client") {
    //   this.props.history.push('/client/dashboard');
    // }
    // this.props.logOut();
    //this.props.history.push('/');
  }

 
  componentDidUpdate() {
    this.menuSelection();
  }
 

  menuSelection() {
    var url = window.location.pathname;
    let splitUrl = url.split('/');
    
    if (splitUrl.length && splitUrl[2] !== undefined && splitUrl[2] === 'dashboard') {
      if (splitUrl[3] !== undefined) {
        $('.nav-link').each(function (index, ele) {
          let hrefVar = $(ele).attr('href');
          console.log('splitUrlsplitUrlhrefVar',$(ele))
          if (hrefVar !== undefined) {
            let splitHref = hrefVar.split('/')
      
            if (splitHref.length && splitHref[2] !== undefined && splitHref[2] === 'dashboard') {
              console.log('splitUrlsplitUrl135',$(ele).addClass("active"))
              $(ele).addClass("active");
            }
          }

        })

      }
    }
    if (splitUrl.length && splitUrl[2] !== undefined && splitUrl[2] === 'all-chat') {
      if (splitUrl[3] !== undefined) {
        $('.nav-link').each(function (index, ele) {
          let hrefVar = $(ele).attr('href');
        // //  $('.nav-tabs li:first').addClass('active');
        // //  $(".nav-link li:nth-child(1)").find('a:first').addClass("st_first_tab");
        // $("ul.nav li:nth-child(1)").find('a').addClass("st_first_tab");
        // $('ul.menu li:first-child').addClass('current');

        //  $("ul li:nth-child(1)").find('a:first').addClass("active");
        //  $('ul.nav li:first-child a:first').addClass('current');
        //  $("ul li:nth-child(1) a:first ").addClass("active"); //first li

          if (hrefVar !== undefined) {
            let splitHref = hrefVar.split('/')
      
            if (splitHref.length && splitHref[2] !== undefined && splitHref[2] === 'all-chat') {
              
              $(ele).addClass("active");
            }
          }

        })

      }
    }
  }

  render() {
    
    const { user } = this.props;
    
    const redirectUrl = user.role === 'admin' ? "/agent/dashboard/1?reload=true" : '';
    return (
      user.role === 'admin' ? <Redirect to={redirectUrl} /> :
        <Site.Wrapper
          headerProps={{
            to: '/',
            alt: "Voiceoc",
            imageURL: process.env.REACT_APP_publicUrl + '/images/logo-voiceoc-new.svg',
            accountDropdown: {
              avatarURL: `${process.env.REACT_APP_baseUrl}/content/logo.png`,
              name: user.name,
              description: "Administrator",
              options: [
                {
                  icon: "unlock", value: changeLanguage(this.props.siteLanguage,'change_password') , onClick: () => {
                    this.routeChange('/admin/change-password');
                  }
                },
                { isDivider: true },
                {
                  icon: "settings", value: changeLanguage(this.props.siteLanguage,'inchat_setting'), onClick: () => {
                    this.routeChange('/admin/settings');
                  }
                },
                { isDivider: true },
                {
                  icon: "grid", value: changeLanguage(this.props.siteLanguage,'endchat_setting'), onClick: () => {
                    this.routeChange('/admin/end-settings');
                  }
                },
                {
                  icon: "cpu", value: changeLanguage(this.props.siteLanguage,'google_setting'), onClick: () => {
                    this.routeChange('/admin/google-settings');
                  }
                },
                // {
                //   icon: "globe", value: `${changeLanguage(this.props.siteLanguage,'Change_language')}: ${this.props.siteLanguage  === 'en' || this.props.siteLanguage  === undefined ? 'ENG' :"ARABIC"} | ${this.props.siteLanguage === 'ar' ? 'ENG' : 'ARABIC'}`, onClick: () => {
                //     this.changeLanguage();
                //   }
                // },
              
                { isDivider: true },
                {
                  icon: "log-out", value: changeLanguage(this.props.siteLanguage,'signout'), onClick: () => {
                    this.routeChange('/login');
                  }
                },
              ],
            },
          }}
          // navProps={{ itemsObjects: this.navBarItems() }}
          navProps={{
            itemsObjects: this.getMenu()
          }}
          routerContextComponentType={withRouter(RouterContextProvider)}
          footerProps={{
            copyright: (
              <React.Fragment>
                Copyright © 2020

                <Link to="/"> Voiceoc Innovations Pvt Ltd. </Link>
                All Rights Reserved
              </React.Fragment>
            ),
          }}
        >
          {this.props.children}

         
        </Site.Wrapper >

  

    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    siteLanguage: state.auth.siteLanguage
  }
}

function mapDispatchToProps(dispatch) {
  return {
    logOut: () => {
      dispatch(authActions.authLogout())
    },
    setLang: (payload) => {
      dispatch(authActions.setLang(payload))
    }
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SiteWrapperSuperAdmin));



import { compose, createStore, applyMiddleware } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducer from "../reducers";

const persistConfig = {
  key: 'voiceoc',
  storage
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewares = [thunk];
if (process.env.NODE_ENV !== 'production') {
  middlewares.push(logger);
}
export const store = createStore(
  persistedReducer,
  compose(applyMiddleware(...middlewares))
);

export const persistor = persistStore(store);
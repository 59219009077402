import React from "react";
import Popup from "reactjs-popup";
import {
  Grid,
  Card,
  Table,
  Page,
  colors,
  Form
} from "tabler-react";

class ReadmorePopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      open: this.props.openPopup ,
      reply: false
    };
    //this.openModal = this.props.openPopup;
    this.closeModal = this.closeModal.bind(this);
  }
 
  closeModal() {
    this.setState({
      reply: false
    });
    this.props.onChange(false);
  }

  showReply(e){
    this.setState({
      reply: true
    });
  }

  submitReply(e){
     this.setState({
      reply: false
    });
    this.props.onChange(false);
  }

  render() {
    const openPopup = this.props.openPopup.open;
    const replyOption = this.props.openPopup.reply || this.state.reply
   // console.log(openPopup)
    return (
      <div>
       
        <Popup
          open={openPopup}
          onClose={this.closeModal}
          closeOnDocumentClick
        >
       
          <div className="card">
            <div className="card-header">
              
              <div className="card-options">
                <a className="card-options-remove cursorPointer" 
                  data-toggle="card-remove" 
                  onClick={() => {
                  this.closeModal();
                }}>
                  <i className="fe fe-x"></i>
                </a>
              </div>
            </div>
            <div className="card-body">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. 
              Aperiam deleniti fugit incidunt, iste, itaque minima 
              neque pariatur perferendis sed suscipit velit vitae 
              voluptatem. A consequuntur, deserunt eaque error nulla 
              temporibus!
            </div>
            
            <div className="card-footer">
             <Form.Group label={<Form.Label>Text</Form.Label>}>
                <Form.Textarea
                  defaultValue=" "
                  name="example-textarea"
                  placeholder="Content.."
                  rows={4}
                />
              </Form.Group>
              <div className="d-flex">
                <button className="btn btn-link" 
                onClick={() => {
                  this.closeModal();
                }}
                >Cancel</button>
                <button className="btn btn-primary ml-auto" type="submit" 
                 onClick={(e) => this.submitReply(e)}>
                  Submit
                </button>
              </div>
            </div>

            

          </div>
          
        </Popup>
      </div>
    );
  }
}

export default ReadmorePopup
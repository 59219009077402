import * as actionTypes from './action-type';

const initialState = {
  isAuthenticated: false,
  user: {},
  jwtToken: '',
  redirectDestination: '/',
  props: {},
  clientInfo: {},
  activeBot: {}
};

const Auth = (state = initialState, { type, payload = null }) => {
  switch (type) {
    case actionTypes.AUTH_LOGIN:
      return authLogin(state, payload);
    case actionTypes.UPDATE_USER_DATA:
      return updateUserData(state, payload);
    case actionTypes.AUTH_CHECK:
      return checkAuth(state);
    case actionTypes.AUTH_LOGOUT:
      return logout(state);
    case actionTypes.SET_REDIRECT_URL:
      return setReedirectUrl(state, payload);
    case actionTypes.SET_TOAST:
      return updateToastState(state, payload);
    case actionTypes.ADMIN_AS_CLIENT_LOGIN:
      return adminLoginAsClient(state, payload);
    case actionTypes.ADMIN_AS_CLIENT_LOGOUT:
      return adminLogoutAsClient(state, payload);
    case actionTypes.SET_ACTIVE_BOT:
      return setActiveBot(state, payload);
      case actionTypes.SET_LANG:
        return setLang(state, payload);
    default:
      return state;
  }
};

const authLogin = (state, payload) => {
  if (payload.statusCode === 200) {
    const jwtToken = payload.data.authToken || null;
    localStorage.setItem('jwtToken', jwtToken);
    //const { user } = payload.data.adminData;
    let newState = {
      isAuthenticated: true,
      user: payload.data.user,
      jwtToken
    };
    state = Object.assign({}, state, newState);
  }
  return state;
};

const checkAuth = (state) => {
  state = Object.assign({}, state, {
    isAuthenticated: !!state.isAuthenticated
  });
  return state;
};

const setReedirectUrl = (state, { path }) => {
  state = Object.assign({}, state, {
    redirectDestination: path
  });
  return state;
};

const setActiveBot = (state, { bot }) => {
  state = Object.assign({}, state, {
    activeBot: bot
  });
  return state;
};

const logout = (state) => {
  localStorage.removeItem('jwtToken');
  state = Object.assign({}, state, {
    isAuthenticated: false,
    user: {}
  });
  return state;
};

const updateUserData = (state, payload) => {
  console.log(payload)
  if (payload) {
    state = Object.assign({}, state, {
      user: payload
    });
  }
  return state;
}
const updateToastState = (state, payload) => {
  state = Object.assign({}, state, payload);
  return state;
}
const setLang = (state, payload) => {
  state = Object.assign({}, state, payload);
  return state;
}

const adminLoginAsClient = (state, payload) => {
  state = Object.assign({}, state, {
    clientInfo: payload
  });
  return state;
}

const adminLogoutAsClient = (state, payload) => {
  console.log('working logout');
  state = Object.assign({}, state, {
    clientInfo: {},
    activeBot: {}
  });
  return state;
}

export default Auth;

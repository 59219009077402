// @flow

import React from "react";
import {
  Form,
  Grid,
  Card,
  Button,
  Icon

} from "tabler-react";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SiteWrapperAdmin from "../../admin/SiteWrapper.admin.react";
import { validate } from '../../validate';
import clientService from "../../services/client";
import Buttonloader from '../../common/Loader/Buttonloader'
import authActions from "../../redux/auth/action";
import { changeLanguage } from '../../../src/language/index'

const validationRules = [
  {
    field: 'oldPassword',
    validations: ['required', 'min:3', 'max:15'],
    name: 'Old Password'
  },
  {
    field: 'newPassword',
    validations: ['required', 'min:3', 'max:15'],
    name: 'New Password'
  },
  {
    field: 'confirmPassword',
    validations: ['required', 'min:3', 'max:100'],
    name: 'Confirm Password'
  },
]

class ClientChangePassword extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fields: {
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      isLoading: false,
      message: {
        style: 'success',
        text: ''
      },
      errors: {}
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleNotification = this.handleNotification.bind(this);
    this.setLoadingToggle = this.setLoadingToggle.bind(this);
  }

  handleChange = (e, field) => {
    const { fields } = this.state, val = e.target.value;
    fields[field] = val;
    this.setState({ fields });
    //this.validate();
  }

  setLoadingToggle(value) {
    this.setState({ isLoading: value })
  }

  // validate
  validate() {
    const { fields } = this.state;
    const { isValid, errors } = validate(fields, validationRules);
    if ((!fields['confirmPassword'] == '') &&
      (fields['confirmPassword'] != fields['newPassword'])) {
      const { isValid } = false;
      errors['confirmPassword'] = 'Confirm Password didn\'t match'
    }
    this.setState({ errors });
    return isValid;
  }

  //form submition
  handleSubmit = async event => {
    event.preventDefault();
    if (this.validate()) {

      this.setLoadingToggle(true);

      const data = {
        oldPassword: this.state.fields.oldPassword,
        newPassword: this.state.fields.newPassword
      }
      clientService.changePassword(data)
        .then(response => {
          toast.success("Password Changed successfully.")
          setTimeout(() => {
            this.props.history.push("/agent/dashboard/1");
          }, 3000)

        })
        .catch(error => {
          this.setLoadingToggle(false);
          this.setState(() => ({ message: { text: error.response.data.message, style: 'danger' } }));
          setTimeout(() => {
            this.handleNotification();
          }, 3000)
        })
    }
    else {
      console.log('eeeeeeee');
    }
  }

  componentDidMount() {
    document.title = "Voiceoc | Change Password"
  }

  handleNotification() {
    this.setState(() => ({ message: { text: '', style: '' } }));
  }

  render() {
    const { message } = this.state;

    return (
      <SiteWrapperAdmin>
        <Grid.Col lg={5} className="mx-auto ">
          <Card>
            <Card.Header>
              <Card.Title>  {changeLanguage(this.props.siteLanguage,'change_password')}</Card.Title>
            </Card.Header>
            <Card.Body>
              {!!message.text ?
                <div className={'alert alert-' + message.style} >
                  <p>{message.text}</p>
                  <Icon prefix="fa" name="times" onClick={this.handleNotification}></Icon>
                </div>
                :
                null
              }
              <form onSubmit={this.handleSubmit}>
                <Form.FieldSet>
                  <Form.Group
                    isRequired
                    label= {changeLanguage(this.props.siteLanguage,'old_password')}
                  >
                    <Form.Input name="oldPassword"
                      type="password"
                      placeholder= {changeLanguage(this.props.siteLanguage,'old_password')}
                      onChange={(e) => this.handleChange(e, 'oldPassword')}
                      value={this.state.fields.oldPassword}
                    />
                    <label style={{ display: this.state.errors.oldPassword ? 'block' : 'none' }} className="error">{this.state.errors.oldPassword}</label>
                  </Form.Group>
                  <Form.Group
                    isRequired
                    label= {changeLanguage(this.props.siteLanguage,'new_password')}
                  >
                    <Form.Input name="newPassword"
                      type="password"
                      placeholder= {changeLanguage(this.props.siteLanguage,'new_password')}
                      onChange={(e) => this.handleChange(e, 'newPassword')}
                      value={this.state.fields.newPassword} />
                    <label style={{ display: this.state.errors.newPassword ? 'block' : 'none' }} className="error">{this.state.errors.newPassword}</label>
                  </Form.Group>
                  <Form.Group
                    isRequired
                    label= {changeLanguage(this.props.siteLanguage,'confirm_password')}
                  >
                    <Form.Input name="confirmPassword"
                      type="password"
                      placeholder= {changeLanguage(this.props.siteLanguage,'confirm_password')}
                      onChange={(e) => this.handleChange(e, 'confirmPassword')}
                      value={this.state.fields.confirmPassword} />
                    <label style={{ display: this.state.errors.confirmPassword ? 'block' : 'none' }} className="error">{this.state.errors.confirmPassword}</label>
                  </Form.Group>

                </Form.FieldSet>

                {!this.state.isLoading ?
                  <Button color="primary btn-block" type="submit"> {changeLanguage(this.props.siteLanguage,'change')}</Button>
                  :
                  <Buttonloader loading={this.state.isLoading}></Buttonloader>
                }
              </form>
            </Card.Body>
          </Card>
        </Grid.Col>
       <ToastContainer autoClose={5000} position={'bottom-right'}/>
      </SiteWrapperAdmin >
    )
  }

}


const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    siteLanguage:state.auth.siteLanguage
  }
}

function mapDispatchToProps(dispatch) {
  return {
    logOut: () => {
      dispatch(authActions.authLogout())
    }
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClientChangePassword));
